.App {
  text-align: center;
  font-family: 'sf-pro-text', sans-serif;
  display: flex;
  justify-content: top;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

h4 {
  margin: 0;
}

h5 {
  margin: 0;
}

p {
  margin: 0;
}
