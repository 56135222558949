/* home.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');

/* ==================== */
/* VARIABLES GLOBALES   */
/* ==================== */
:root {
  /* Couleurs */
  --color-background: #110F17;
  --color-secondary: #2d1a30;
  --color-text: #fff;
  --color-text-muted: rgba(255, 255, 255, 0.9);
  --color-text-subtle: rgba(255, 255, 255, 0.8);
  --color-overlay: rgba(0, 0, 0, 0.7);
  --color-background-light: rgba(255, 255, 255, 0.03);
  --color-background-dark: rgba(0, 0, 0, 0.5);
  --color-background-dark-2: rgba(0, 0, 0, 0.2);
  --color-error: #f44336;
  --color-success: #4caf50;

  
  /* Gradients */
  --gradient-purple: radial-gradient(
    circle at center,
    rgba(150, 85, 166, 0.1) 38%, 
    rgba(16, 15, 19, 0.1) 100%
  );
  --gradient-white: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  --gradient-transition: radial-gradient(
    ellipse at center,
    #39373F 0%,
    #110F17 70%,
    rgba(17, 15, 23, 0) 100%
  );

  /* Typographie */
  --font-size-base: 1rem;          /* 16px */
  --font-size-xs: 0.875rem;        /* 14px */
  --font-size-sm: 1rem;            /* 16px */
  --font-size-md: 1.125rem;        /* 18px */
  --font-size-lg: 1.5rem;          /* 24px */
  --font-size-xl: 2rem;            /* 32px */
  --font-size-2xl: 3rem;           /* 48px */
  --font-size-3xl: 4rem;           /* 64px */
  --font-size-4xl: 5rem;           /* 80px */
  
  /* Font weights */
  --font-weight-light: 200;
  --font-weight-regular: 400;
  --font-weight-bold: 700;

  /* Line heights */
  --line-height-base: 1.6;         /* 24px */
  --line-height-lg: 1.8;           /* 28.8px */
  --line-height-xl: 2;             /* 32px */
  --line-height-2xl: 2.4;          /* 38.4px */
  --line-height-3xl: 3;            /* 48px */
  --line-height-4xl: 4;            /* 64px */

  /* Espacements */
  --spacing-3xs: 0.125rem;            /* 2px */
  --spacing-2xs: 0.25rem;            /* 4px */
  --spacing-xs: 0.5rem;            /* 8px */
  --spacing-sm: 1rem;              /* 16px */
  --spacing-md: 1.5rem;            /* 24px */
  --spacing-lg: 2rem;              /* 32px */
  --spacing-xl: 2.5rem;            /* 40px */
  --spacing-2xl: 3rem;             /* 48px */
  --spacing-2-5xl: 4rem;            /* 64px */
  --spacing-3xl: 5rem;             /* 80px */
  --spacing-3-5xl: 6rem;           /* 96px */
  --spacing-4xl: 7.5rem;           /* 120px */
  --spacing-4-5xl: 8rem;           /* 128px */
  --spacing-5xl: 10rem;           /* 160px */

  /* Sizes */
  --size-1xs: 0.25rem;             /* 4px */
  --size-2xs: 0.5rem;             /* 8px */
  --size-sm: 1rem;                  /* 16px */
  --size-md: 1.5rem;                /* 24px */
  --size-lg: 2rem;                  /* 32px */
  --size-xl: 2.5rem;                /* 40px */
  --size-2xl: 3rem;                 /* 48px */
  --size-2-5xl: 4rem;                /* 64px */
  --size-3xl: 5rem;                 /* 80px */
  --size-3-5xl: 6rem;                /* 96px */
  --size-4xl: 8rem;                 /* 128px */
  --size-4-5xl: 9rem;                /* 144px */
  --size-5xl: 10rem;                /* 160px */
  --size-6xl: 12rem;                /* 192px */
  --size-7xl: 14rem;                /* 224px */
  --size-8xl: 16rem;                /* 256px */
  --size-9xl: 18rem;                /* 288px */
  --size-9-5xl: 18.8rem;            /* 300px */
  --size-10xl: 20rem;               /* 320px */
  --size-11xl: 22rem;               /* 352px */
  --size-12xl: 24rem;               /* 384px */
  --size-13xl: 26rem;               /* 416px */
  --size-14xl: 28rem;               /* 448px */
  --size-15xl: 30rem;               /* 480px */
  --size-16xl: 32rem;               /* 512px */
  --size-17xl: 34rem;               /* 544px */
  --size-18xl: 36rem;               /* 576px */
  --size-19xl: 38rem;               /* 608px */
  --size-20xl: 40rem;               /* 640px */
  --size-21xl: 42rem;               /* 672px */
  --size-22xl: 44rem;               /* 704px */
  --size-23xl: 46rem;               /* 736px */
  --size-24xl: 48rem;               /* 768px */
  --size-25xl: 50rem;               /* 800px */
  --size-26xl: 52rem;               /* 832px */
  --size-27xl: 54rem;               /* 864px */
  --size-28xl: 56rem;               /* 896px */
  --size-29xl: 58rem;               /* 928px */
  --size-30xl: 60rem;               /* 960px */
  --size-31xl: 62rem;               /* 992px */
  --size-32-5xl: 62.7rem;           /* 1000px */
  

  /* Border Radius */
  --border-radius-sm: 0.5rem;         /* 8px */
  --border-radius-md: 1rem;           /* 16px */
  --border-radius-lg: 1.25rem;        /* 20px */
  --border-radius-xl: 1.5rem;         /* 24px */
  --border-radius-container: 19rem;   /* 304px */
  --border-radius-container-sm: 12rem;/* 192px */
  --border-radius-container-xs: 6rem; /* 96px */
  --border-radius-full: 999px;
  --border-radius-expand: 50%;

  /* Shadows */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.2);
  --shadow-md: 0 4px 12px rgba(0, 0, 0, 0.3);
  --shadow-lg: 0 8px 32px rgba(0, 0, 0, 0.25);
  --shadow-special: 0 -35px 44px rgba(34, 34, 34, 0.33);
  --shadow-special-2: 0 35px 44px rgba(34, 34, 34, 0.33);
  --shadow-expand: 0px 0px 150px rgba(0, 0, 0, 0.15), 0px 0px 120px rgba(0, 0, 0, 1);

  /* Transitions */
  --transition-fast: 0.3s ease;
  --transition-medium: 0.5s ease;
  --transition-slow: 0.8s ease;
  --transition-bounce: cubic-bezier(0.34, 1.56, 0.64, 1);
  --transition-carousel: 0.1s linear;
  --transition-expand: 0.2s ease;

  /* Z-index */
  --z-base: 1;
  --z-overlay: 10;
  --z-modal: 100;
  --z-fixed: 1000;

  /* Opacités */
  --opacity-muted: 0.8;
  --opacity-overlay: 0.5;
  
  /* Dimensions */
  --container-max-width: 75rem;    /* 1200px */
  --container-max-width-sm: 50rem; /* 640px */
}

/* ==================== */
/* STYLES GLOBAUX  */
/* ==================== */

html {
  font-size: 16px;
  color: var(--color-text);
  background-color: var(--color-background);
}

/* Ajustement pour les tablettes */
@media (max-width: 1024px) {
  html {
    font-size: 14px;
  }
}

/* Ajustement pour les mobiles */
@media (max-width: 768px) {
  html {
    font-size: 12px; 
  }
}


h1 {
  font-size: var(--font-size-3xl);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.08rem;
}

h2 {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-light);
  letter-spacing: 0.08rem;
}

h3 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.08rem;
}

h4 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-regular);
  letter-spacing: 0.08rem;
  margin: var(--spacing-xs);
}

h5 {
  font-size: var(--font-size-2xl);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-sm);
  font-family: 'Roboto Condensed', sans-serif;
  text-transform: uppercase;
}

h6 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-bold);
}

p {
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-base);
}

/* ==================== */
/* ANIMATIONS          */
/* ==================== */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0) translateX(-50%);
  }
  50% {
    transform: translateY(-1.25rem) translateX(-50%); /* 20px en rem */
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px) translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

@keyframes fadeInUpArrow {
  from {
    opacity: 0;
    transform: translateY(30px) translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}

@keyframes fadeInUpStats {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUpSocial {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0) translateX(-50%);
  }
  50% {
    transform: translateY(-1.25rem) translateX(-50%);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.container {
  width: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*overflow-x: hidden;*/
}

/* ==================== */
/* SECTION HERO        */
/* ==================== */

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  margin: var(--spacing-xl);
  width: stretch;
  max-width: var(--container-max-width);
  box-sizing: border-box;
  color: var(--color-text);
}

.hero-content {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  width: stretch;
}

.headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: stretch;
  max-width:  var(--container-max-width);
}

.headline-text {
  margin-right: var(--spacing-xl);
}

.title {
  text-align: left;
  opacity: 0;
  animation: fadeInUpContent var(--transition-medium) forwards;
  animation-delay: 0.2s;
  margin-bottom: var(--spacing-sm);
}

.subtitle {
  text-align: left;
  white-space: pre-line;
  opacity: 0;
  animation: fadeInUpContent var(--transition-medium) forwards;
  animation-delay: 0.7s;
}

.profile-pic {
  width: 24rem;
  opacity: 0;
  animation: fadeInRight var(--transition-slow) forwards;
  animation-delay: 1.2s;
}

.bottomrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.stats-container {
  display: flex;
  gap: var(--spacing-md);
}

.stat-box {
  background-image: 
  var(--gradient-purple),
  var(--gradient-white);
  width: var(--size-4-5xl);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-md);
  transition: background-image var(--transition-fast);
  opacity: 0;
  animation: fadeInUpStats var(--transition-medium) forwards;
}

.stat-number {
  display: block;
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-bold);
}

.stat-label {
  display: block;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-light);
  opacity: var(--opacity-muted);
}

.socials {
  display: flex;
  gap: var(--spacing-sm);
}

.social-link {
  opacity: 0;
  animation: fadeInUpSocial var(--transition-medium) forwards;
}

.social-link:nth-child(1) {
  animation-delay: 2.5s;
}

.social-link:nth-child(2) {
  animation-delay: 2.7s;
}

.arrow-down {
  position: absolute;
  bottom: var(--spacing-2xl);
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  animation: fadeInUpArrow var(--transition-medium) forwards,
             bounce 2s ease-in-out infinite;
  animation-delay: 3s, 4s;
}

.arrow-down.visible {
  opacity: 1;
  animation: bounce 2s ease-in-out infinite;
  animation-delay: 4s;
}

@media (max-width: 1024px) {
  .hero-container {
    min-height: 95vh;
  }
  .bottomrow{
    flex-direction: column;
  }
  .socials{
    margin-top: var(--spacing-lg);
    width: stretch;
    justify-content: right;
  }
  .stats-container {
    width: stretch;
    justify-content: space-between;
    margin-top: var(--spacing-lg);
  }
  .stat-box{
    width: 100%;
  }
  .headline-text {
    width: stretch;
  }
}

@media (max-width: 766px) {
  .hero-container {
    align-items: flex-start;
    min-height: auto;
  }
  .headline{
    flex-direction: column;
   }
  .bottomrow{
    flex-direction: column;
  }
  .stats-container{
    flex-direction: column;
    width: stretch;
    align-items: center;
    margin: var(--spacing-xl);
  }
  .stat-box{
    width: 100%;
  }
  .stat-number{
    font-size: var(--font-size-2xl);
  }
  .stat-label{
    font-size: var(--font-size-lg);
  }
  .socials{
    margin-top: var(--spacing-lg);
    width: stretch;
    justify-content: center;
  }
  .social-link {
    transform: translateY(0);
  }
  .arrow-down{
    display: none;
  }
}

/* ==================== */
/* SECTION PROJECTS */
/* ==================== */

.projects-section {
  margin: var(--spacing-xl);
  width: stretch;
  max-width: var(--container-max-width);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-3xl);
}

.projects-grid {
  width: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.first-row, .second-row {
  display: flex;
  gap: var(--spacing-lg);
  justify-content: center;
  width: stretch;
}

.project-card {
  cursor: pointer;
  height: var(--size-7xl);
  border-radius: var(--border-radius-md);
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: var(--shadow-md);
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  text-align: left;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity var(--transition-medium), transform var(--transition-medium);
}

.first-row .project-card:first-child {
  width: calc(74% - var(--spacing-lg));
}

.first-row .project-card:last-child {
  width: calc(33% - var(--spacing-lg));
}

.second-row .project-card {
  width: calc(33% - var(--spacing-lg));
}

.project-card.visible {
  opacity: 1;
  transform: translateY(0);
}

.project-card:hover {
  transform: scale(1.03);
  transition: all 0.6s cubic-bezier(0.4, 0, 0, 1);
}

.project-card::before {
  content: '';
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
  z-index: 1;
  transition: opacity 0.3s ease;
}

.project-card h3,
.project-card p {
  position: relative;
  z-index: 2;
}

.project-card h3 {
  margin: 0 0 var(--spacing-xs);
}

.project-card:hover::before {
  opacity: 0.8;
}

.stat-box:nth-child(1) {
  animation-delay: 1.7s;
}
.stat-box:nth-child(2) {
  animation-delay: 1.9s;
}
.stat-box:nth-child(3) {
  animation-delay: 2.1s;
}
.stat-box:nth-child(4) {
  animation-delay: 2.3s;
}

@media (max-width: 1024px) {
  .first-row .project-card:first-child {
    width: calc(75% - var(--spacing-lg));
  }
}

@media (max-width: 766px) {
  .first-row .project-card:first-child {
    width: stretch;
  }
  .first-row .project-card:last-child {
    width: stretch;
  }
  .second-row .project-card {
    width: stretch;
  }
  .first-row {
    flex-direction: column;
  }
  .second-row {
    flex-direction: column;
  }
}

/* ==================== */
/* GRADIENT TRANSITION */
/* ==================== */

.gradient-transition {
  height: var(--size-3xl);
  margin-top: var(--spacing-3xl);
  margin-bottom: var(--spacing-3xl);
  background: var(--gradient-transition);
  width: stretch;
}

/* ==================== */
/* SECTION PARCELCHAIN */
/* ==================== */ 

.parcelchain-section {
  padding: var(--spacing-3xl) 0;
  text-align: center;
  width: stretch;
  position: relative;
  z-index: var(--z-index-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xl);
  overflow: hidden;
}

/* PARCELCHAIN HEADING */

.parcelchain-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 var(--spacing-lg);
  max-width: var(--container-max-width);
}

.parcelchain-title,
.parcelchain-subtitle,
.parcelchain-desc {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity var(--transition-slow), transform var(--transition-slow);
}

.parcelchain-title.visible,
.parcelchain-subtitle.visible,
.parcelchain-desc.visible {
  opacity: 1;
  transform: translateY(0);
}

.parcelchain-title.visible {
  transition-delay: 0.2s;
}

.parcelchain-subtitle.visible {
  transition-delay: 0.6s;
}

.parcelchain-desc.visible {
  transition-delay: 1.5s;
  opacity: var(--opacity-muted);
}

.parcelchain-desc {
  margin: var(--spacing-xs);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-base);
}

/* PARCELCHAIN MOCKUPS */

.parcelchain-mockups {
  width: stretch;
  position: relative;
  margin-top: calc(-1 * var(--spacing-xl));
  overflow: visible;
  height: var(--size-19xl);
}

.app-badges {
  display: flex;
  gap: var(--spacing-sm);
  justify-content: center;
  margin: var(--spacing-sm) 0 0 0;
}

.app-badges a {
  display: inline-block;
  transition: transform var(--transition-slow) ease;
}

.app-badges a:hover {
  transform: scale(1.05);
}

@media (max-width: 1024px) {
  .parcelchain-mockups {
   height: var(--size-18xl);
  }
 }
 
@media (max-width: 766px) {
  .parcelchain-mockups {
    height: var(--size-20xl);
  }
  .app-badges {
    display: flex;
    flex-direction: column;
  }
  .app-badges img {
    width: var(--size-6xl);
  }
}

/* PARCELCHAIN BRAND DESIGN */

.brand-design {
  margin-top: var(--spacing-lg);
  width: stretch;
}

.brand-design-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-2xl) var(--spacing-md) var(--spacing-4xl) var(--spacing-md);
  border-radius: var(--border-radius-container) var(--border-radius-container) 0 0;
  box-shadow: var(--shadow-special);
}

@media (max-width: 1024px) {
  .brand-design-text {
    border-radius: var(--border-radius-container-sm) var(--border-radius-container-sm) 0 0;
  }
  .brand-logo-container {
    height: 320px;
    justify-content: center;
   }
  .parcelchain-section .callout-left p {
    right: 220px!important;
    top: -340px!important;
    width: 200px!important;
  }

  .parcelchain-section .callout-right p {
    left: 260px!important;
    top: -220px!important;
    width: 200px!important;
  }
}
 
 @media (max-width: 766px) {
  .brand-design-text {
    border-radius: var(--border-radius-container-xs) var(--border-radius-container-xs) 0 0;
  }
  .brand-logo-container {
    height: 700px;
    justify-content: center;
  }

  .parcelchain-section .callout-left p {
    right: 0px!important;
    top: -540px!important;
    width: 240px!important;
  }

  .parcelchain-section .callout-right p {
    left: 0px!important;
    top: 40px!important;
    width: 240px!important;
    text-align: right;
  }

  .brand-design-conclusion {
    width: stretch!important;
    margin-left: var(--spacing-md)!important;
    margin-right: var(--spacing-md)!important;
    margin-top: var(--spacing-3xl)!important;
  }
}

.brand-design-intro {
  opacity: var(--opacity-muted);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-base);
  max-width: var(--container-max-width-sm);
}

.brand-logo-container {
  position: relative;
  margin: auto;
  max-width: var(--container-max-width);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brand-logo {
  display: block;
  margin: 0 auto;
  max-width: var(--size-16xl); 
}

.parcelchain-section .callout-left,
.parcelchain-section .callout-right {
  position: relative;
  width: var(--size-7xl);
}

.parcelchain-section .callout-left p,
.parcelchain-section .callout-right p {
  opacity: 0;
  transform: translateY(20px);
  text-align: left;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-base); 
}

.parcelchain-section .callout-left p,
.parcelchain-section .callout-right p:visible {
  opacity: var(--opacity-muted);
}

.parcelchain-section .callout-left svg path,
.parcelchain-section .callout-right svg path {
  stroke-dasharray:0!important;
}

.brand-design-conclusion {
  margin-top: var(--spacing-xl);
  max-width: var(--size-14xl);
  margin-right: auto;
  margin-left: auto;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-base);
  transform: translateY(20px);
}

.parcelchain-section .dot {
  position: absolute;
  width: var(--size-2xs);
  height: var(--size-2xs);
  background-image: var(--dot-image);
  background-size: contain;
  background-repeat: no-repeat;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from { box-shadow: 0 0 5px rgba(204, 204, 204, 0.3); }
  to   { box-shadow: 0 0 10px rgba(204, 204, 204, 0.7); }
}

/* PARCELCHAIN CAROUSEL */

.mockups-carousel-container {
  position: relative;
  width: stretch;
  height: var(--size-16xl);
  margin: var(--spacing-3-5xl) 0;
  overflow: hidden;
}

.mockups-carousel {
  position: relative;
  display: flex;
  transition: transform var(--transition-carousel);
}

.mockups-set {
  display: flex;
  gap: var(--spacing-xl);
  padding: 0 var(--spacing-sm);
}

.mockup-item {
  flex: 0 0 auto;
  width: var(--size-16xl);
  height: var(--size-16xl);
  overflow: visible;
  transition: transform var(--transition-fast);
}

.mockup-item:hover {
  transform: scale(1.02) translateY(-10px);
}

.mockup-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: visible;
}

/* PARCELCHAIN UX/UI DESIGN */
.UI-parcelChain {
  overflow: hidden;
  text-align: center;
  margin-top: - var(--spacing-lg);
  width: stretch;
}

.UI-parcelChain-text {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-2xl) var(--spacing-md) var(--spacing-4xl) var(--spacing-md);
  border-radius: 0 0 var(--border-radius-container) var(--border-radius-container);
  box-shadow: var(--shadow-special-2);
  z-index: var(--z-modal);
  background-color: var(--color-background);
}

.UI-parcelChain-desc {
  opacity: var(--opacity-muted);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-base);
  max-width: var(--container-max-width-sm);
}

.logos-sponsor {
  display: flex;
  width: stretch;
  flex-direction: row;
  gap: var(--spacing-2xl);
  justify-content: center;
  position: relative;
  margin-top: -600px;
  height: var(--size-9xl);
  background-color: var(--color-background);
  border-radius: var(--border-radius-container) var(--border-radius-container) 0 0;
  box-shadow: var(--shadow-special);
  z-index: var(--z-modal);
}

.logos-sponsor-left {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: var(--spacing-2xl);
}

.logos-sponsor-right {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: var(--spacing-2xl);
}

.logos-sponsor img {
  width: var(--size-3-5xl);
  height: auto;
}

#googleicon {
  width: var(--size-9xl);
  height: auto;
}

.UI-parcelChain-mockups {
  width: stretch;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  padding: var(--spacing-xl);
  top: -300px;
  z-index: var(--z-overlay);
}

.iphone-mockups-grid {
  position: relative;
  width: var(--size-32-5xl);
  height: 100%;
  margin: 0 auto;
  z-index: var(--z-overlay);
}

.iphone-mockup-item {
  position: absolute;
  width: var(--size-9-5xl);
  height: auto;
  border-radius: var(--border-radius-md);
  box-shadow: var(--shadow-md);
  object-fit: cover;
  z-index: var(--z-base);
  opacity: 0;
  transform: scale(0) rotate(var(--rand-rotate));
  transition: all 0.5s var(--transition-bounce);
  will-change: transform, opacity;
  z-index: var(--z-overlay);
}

.iphone-mockup-item:hover {
  transform: scale(1.05) rotate(var(--rand-rotate));
}

/* Animation delay pour chaque iPhone */
.iphone-mockup-item:nth-child(1) { animation-delay: 0.1s; }
.iphone-mockup-item:nth-child(2) { animation-delay: 0.2s; }
.iphone-mockup-item:nth-child(3) { animation-delay: 0.3s; }
.iphone-mockup-item:nth-child(4) { animation-delay: 0.4s; }
.iphone-mockup-item:nth-child(5) { animation-delay: 0.5s; }
.iphone-mockup-item:nth-child(6) { animation-delay: 0.6s; }
.iphone-mockup-item:nth-child(7) { animation-delay: 0.7s; }
.iphone-mockup-item:nth-child(8) { animation-delay: 0.8s; }
.iphone-mockup-item:nth-child(9) { animation-delay: 0.9s; }
.iphone-mockup-item:nth-child(10) { animation-delay: 1.0s; }

/* Bouton expand */
.expand-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: var(--z-overlay);
  background-color: var(--color-text);
  width: var(--size-4xl);
  height: var(--size-4xl);
  border-radius: var(--border-radius-expand);
  box-shadow: var(--shadow-expand);
}

.expand-button img {
  width: var(--size-2xl);
  height: var(--size-2xl);
  transition: transform var(--transition-expand);
}

.expand-button:hover img {
  transform: scale(1.1);
}

@media (max-width: 1024px) {
  .UI-parcelChain-text {
    border-radius: 0 0 var(--border-radius-container-sm) var(--border-radius-container-sm);
  }
  .logos-sponsor {
    border-radius: var(--border-radius-container-sm) var(--border-radius-container-sm) 0 0;
  }
  .iphone-mockups-grid {
    width: var(--size-15xl);
  }
  .iphone-mockup-item {
    width: var(--size-7xl);
  }
}

@media (max-width: 766px) {
  .UI-parcelChain-text {
    border-radius: 0 0 var(--border-radius-container-xs) var(--border-radius-container-xs);
    padding: var(--spacing-2xl) var(--spacing-md) var(--spacing-3xl) var(--spacing-md);
  }
  .logos-sponsor {
    border-radius: var(--border-radius-container-xs) var(--border-radius-container-xs) 0 0;
    flex-direction: column;
    align-items: center;
  }
  .logos-sponsor img {
    width: var(--size-3xl);
    height: auto;
  }
  #googleicon {
  width: var(--size-10xl);
  height: auto;
  }
  .logos-sponsor-left {
    flex-direction: column;
  }
  .logos-sponsor-right {
    flex-direction: row;
  }
  .iphone-mockups-grid {
    width: var(--size-10xl);
    margin: 0;
  }
  .iphone-mockup-item {
    width: var(--size-6xl);
  }
}

/* ==================== */  
/* DASHSTUDIO SECTION */
/* ==================== */

.dashstudio-section {
  padding: var(--spacing-3xl) 0 0 0;
  text-align: center;
  width: stretch;
  position: relative;
  z-index: var(--z-index-100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xl);
  overflow: hidden;
}

.dashstudio-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 var(--spacing-lg);
  max-width: var(--container-max-width);
}

.dashstudio-title,
.dashstudio-subtitle,
.dashstudio-desc {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity var(--transition-slow), transform var(--transition-slow);
}

.dashstudio-title.visible,
.dashstudio-subtitle.visible,
.dashstudio-desc.visible {
  opacity: 1;
  transform: translateY(0);
}

.dashstudio-title.visible {
  transition-delay: 0.2s;
}

.dashstudio-subtitle.visible {
  transition-delay: 0.6s;
}

.dashstudio-desc.visible {
  transition-delay: 1.5s;
  opacity: var(--opacity-muted);
}

.dashstudio-mockups {
  width: stretch;
  height: var(--size-19xl);
  position: relative;
  margin: var(--spacing-3xl) 0;
  overflow: hidden;
}

.UI-dashstudio {
  margin-top: var(--spacing-5xl);
  position: relative;
  min-height: var(--size-25xl);
  width: stretch;
}

@media (max-width: 766px) {
  .dashstudio-mockups {
    height: var(--size-12xl)
  }
}

.UI-dashstudio-text {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-sm);
  padding: var(--spacing-2xl) var(--spacing-md) var(--spacing-4xl) var(--spacing-md);
  border-radius: 0 0 var(--border-radius-container) var(--border-radius-container);
  box-shadow: var(--shadow-special-2);
  z-index: var(--z-modal);
  background-color: var(--color-background);
}

.development-container {
  display: flex;
  width: stretch;
  flex-direction: column;
  gap: var(--spacing-2xl);
  align-items: center;
  position: relative;
  margin-top: -600px;
  height: fit-content;
  background-color: var(--color-background);
  border-radius: var(--border-radius-container) var(--border-radius-container) 0 0;
  box-shadow: var(--shadow-special);
  z-index: var(--z-modal);
  padding: var(--spacing-2xl);
}

.development-desc {
  opacity: var(--opacity-muted);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-base);
  max-width: var(--container-max-width-sm);
}

.development-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-2-5xl);
  flex-wrap: wrap;
  margin-top: var(--spacing-xl);
}

.development-icons img {
  height: var(--size-2-5xl);
  width: auto;
  object-fit: contain;
  opacity: var(--opacity-muted);
  transition: opacity var(--transition-fast);
}

.development-icons img:hover {
  opacity: 1;
}

.dashstudio-section .callout-left,
.callout-right {
  position: relative;
  width: var(--size-7xl);
}

.dashstudio-section .callout-left p,
.dashstudio-section .callout-right p {
  opacity: 0;
  transform: translateY(20px);
  text-align: left;
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-base); 
}

.dashstudio-section .callout-left p,
.dashstudio-section .callout-right p:visible {
  opacity: var(--opacity-muted);
}

.dashstudio-section .callout-left svg path, .dashstudio-section .callout-right svg path {
  stroke-dasharray:0!important;
}

.dashstudio-section .dot {
  position: absolute;
  width: var(--size-2xs);
  height: var(--size-2xs);
  background-image: var(--dot-image);
  background-size: contain;
  background-repeat: no-repeat;
  animation: glow 1.5s ease-in-out infinite alternate;
}

.dashstudio-section .callout-left p {
  right: 380px!important;
  top: -420px!important;
}
.dashstudio-section .callout-right p {
left: 400px!important;
  top: -280px!important;
}

@media (max-width: 1024px) {
  .UI-dashstudio-text {
    border-radius: 0 0 var(--border-radius-container-sm) var(--border-radius-container-sm);
  }
  .development-container {
    border-radius: var(--border-radius-container-sm) var(--border-radius-container-sm) 0 0;
  }
  .dashstudio-section .callout-left p {
      right: 260px!important;
      top: -340px!important;
      width: 180px!important;
  }
  .dashstudio-section .callout-right p {
    left: 280px!important;
    top: -200px!important;
    width: 170px!important;
  }
}

@media (max-width: 766px) {
  .UI-dashstudio-text {
    border-radius: 0 0 var(--border-radius-container-xs) var(--border-radius-container-xs);
    padding: var(--spacing-2xl) var(--spacing-md) var(--spacing-3xl) var(--spacing-md);
  }
  .development-container {
    border-radius: var(--border-radius-container-xs) var(--border-radius-container-xs) 0 0;
    padding: var(--spacing-2xl) var(--spacing-md) var(--spacing-3xl) var(--spacing-md);
  }
  .development-icons img {
    height: var(--size-2xl);
  }
  .dashstudio-section .callout-left p {
    right: -40px!important;
    top: -540px!important;
    width: 280px!important;
}
  .dashstudio-section .callout-right p {
    left: -40px!important;
    top: 50px!important;
    width: 280px!important;
  }
}

/* ==================== */  
/* WHY WORK WITH ME SECTION */
/* ==================== */

.why-work-with-me {
  padding: var(--spacing-4xl) var(--spacing-3xl);
  width: stretch;
}

.why-title {
  width: stretch;
  margin-bottom: var(--spacing-2-5xl);
}

.why-grid {
  display: flex;
  gap: var(--spacing-2xl);
  justify-content: center;
  max-width: var(--container-max-width);
  margin: 0 auto;
}

.why-column-left {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2xl);
  width: 60%;
  height: 600px;
}

.why-column-right {
  width: 40%;
  height: 600px;
}

.why-card {
  position: relative;
  border-radius: var(--border-radius-xl);
  padding: var(--spacing-xl);
  box-shadow: var(--shadow-lg);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  transition: transform var(--transition-fast);
  overflow: hidden;
}

.why-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  z-index: 0;
  pointer-events: none;
}

.why-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--gradient-bg);
  background-size: cover;
  background-position: center;
  opacity: var(--opacity-overlay);
  z-index: var(--z-base);
  pointer-events: none;
}

.why-card-content {
  position: relative;
  pointer-events: none;
  height: 100%;
  text-align: left;
  max-width: var(--size-13xl);
  overflow: visible;
}

.why-card-content-vertical {
  position: relative;
  z-index: 2;
  pointer-events: none;
  height: fit-content;
  text-align: left;
  max-width: 400px;
  padding-bottom: 0;
  padding-top: 30px;
  overflow: visible;
}

.why-icon-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.why-icon-container > div {
  width: 100% !important;
  height: 100% !important;
  overflow: visible;
  background: transparent;
}

.eye-container {
  width: 250px;
  height: 200px;
  overflow: visible;
}

.spiral-container {
  width: 200px;
  height: 200px;
  overflow: visible;
}

.pill-container {
  width: 100%;
  height: 350px;
  margin-top: 40px;
  overflow: visible;
}

.horizontal-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-xl);
  height: calc(50% - 20px);
}

.vertical-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--spacing-xl);
  height: 600px;
  padding-bottom: 0;
  padding-top: 0;
}

.why-card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.why-card-text {
  font-size: 16px;
  line-height: 1.5;
}

.why-card:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 1024px) {
  .why-grid {
    flex-direction: column;
  }
  .why-card-content {
    max-width: unset;
  }
  .why-card {
    flex-direction: column-reverse;
    height: fit-content;
    width: stretch;
  }
  .eye-container {
    width: 350px;
    height: 350px;
    margin-right: 20px;
    overflow: visible;
  }
  .spiral-container {
    width: 350px;
    height: 350px;
    margin-right: 20px;
    overflow: visible;
  }
  .pill-container {
    height: 400px;
    width: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pill-container canvas {
    width: 400px!important;
    height: 400px!important;
  }
  .eye-container canvas {
    width: 350px!important;
    height: 350px!important;
  }
  .spiral-container canvas {
    width: 350px!important;
    height: 350px!important;
  }
  .why-icon-container div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .why-column-left {
    width: 100%;
    height: fit-content;  
  }
  .why-column-right {
    width: 100%;
    height: fit-content;
  }
  .why-work-with-me {
    padding: var(--spacing-4xl);
  }
  .vertical-layout {
    padding: var(--spacing-xl);
  }
}

@media screen and (max-width: 766px) {
  .why-grid {
    flex-direction: column;
  }
  .why-card {
    flex-direction: column-reverse;
    height: fit-content;
  }
  .pill-container {
    height: 200px;
    width: stretch;
  }
  .pill-container canvas {
    width: 250px!important;
    height: 250px!important;
  }
  .why-icon-container div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .why-column-left {
    width: 100%;
    height: fit-content;  
  }
  .why-column-right {
    width: 100%;
    height: fit-content;
  }
  .why-work-with-me {
    padding: var(--spacing-xl);
  }
}

/* ==================== */  
/* CONTACT SECTION */ 
/* ==================== */

.contact-section {
  padding: var(--spacing-4xl) var(--spacing-3xl);
  background-color: var(--color-background);
}

.contact-content {
  max-width: var(--container-max-width);
  margin: 0 auto;
  display: flex;
  gap: var(--spacing-lg);
  align-items: flex-start;
}

.contact-text {
  flex: 1;
  padding-top: var(--spacing-2xl);
  text-align: left;
}

.contact-title {
  margin-bottom: var(--spacing-sm);
}

.contact-subtitle {
  opacity: var(--opacity-muted);
  margin-right: var(--spacing-2xl);
}

.contact-form {
  flex: 1;
  background: var(--color-background-light);
  padding: var(--spacing-2xl);
  border-radius: var(--border-radius-xl);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.recaptcha-container {
  margin: var(--spacing-2xl) 0;
  display: none;
  justify-content: center;
  opacity: 0;
}

.g-recaptcha {
  transform-origin: center;
  -webkit-transform-origin: center;
}

.form-group input,
.form-group textarea {
  width: stretch;
  padding: var(--spacing-md);
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: var(--border-radius-xl);
  transition: all var(--transition-fast);
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: var(--color-background-light);
  background: var(--color-text-subtle);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.send-button {
  width: 100%;
  padding: var(--spacing-md);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), var(--gradient-bg);
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  color: var(--color-text);
  border: none;
  border-radius: var(--border-radius-xl);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-bold);
  cursor: pointer;
  transition: all var(--transition-fast);
  position: relative;
  overflow: hidden;
}

.send-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--gradient-bg);
  background-size: cover;
  background-position: center;
  opacity: 0.5;
  z-index: 1;
  pointer-events: none;
}

.send-button span {
  position: relative;
  z-index: 2;
}

.send-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.submit-status {
  margin-top: var(--spacing-md);
  padding: var(--spacing-md);
  border-radius: var(--border-radius-xl);
  text-align: center;
}

.submit-status.success {
  background: rgba(0, 255, 0, 0.1);
  color: var(--color-success);
}

.submit-status.error {
  background: rgba(255, 0, 0, 0.1);
  color: var(--color-error);
}

@media screen and (max-width: 1024px) {
  .contact-section {
    padding: var(--spacing-4xl);
  }
  .contact-content {
    flex-direction: column;
    align-items: center;
  }
  .contact-text {
    padding-top: var(--spacing-xl);
  }
  .contact-form {
    width: stretch;
    padding: var(--spacing-lg);
  }
}

@media screen and (max-width: 766px) {
  .contact-section {
    padding: var(--spacing-xl);
  }
}

/* ==================== */  
/* FOOTER SECTION */ 
/* ==================== */

.footer {
  background-color: var(--color-secondary);
  color: var(--color-text);
  padding: var(--spacing-xl);
  display: flex;
  justify-content: center;
  width: stretch;
  margin-top: var(--spacing-4xl);
}

.footer-content {
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: flex-start;
  width: stretch;
  gap: var(--spacing-2xl);
  max-width: var(--container-max-width);
}

.footer-left, .footer-middle {
  flex: 1;
}

.footer-right {
  flex: 0.8;
}

.footer-title {
  margin-bottom: var(--spacing-md);
}

.footer-text {
  opacity: var(--opacity-muted);
  max-width: 400px;
  margin-bottom: var(--spacing-md);
}

.footer h6 {
  margin-block-start: 0;
}

.footer-button {
  background: linear-gradient(90deg, #7e3fa6, #aa5cc3);
  padding: var(--spacing-xs) var(--spacing-md);
  font-weight: var(--font-weight-bold);
  color: var(--color-text);
  border: none;
  border-radius: var(--border-radius-xl);
  cursor: pointer;
  margin-top: var(--spacing-md);
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0.8;
}

.footer-button:hover {
  transform: scale(1.02);
  opacity: 1;
}

.footer-subtitle {
  margin-bottom: var(--spacing-md);
}

.footer-info {
  margin-bottom: var(--spacing-xs);
}

.footer-info a {
  opacity: var(--opacity-muted);
  color: var(--color-text);
  text-decoration: none;
}

.footer-info a:hover {
  text-decoration: underline;
}

.footer-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-text);
  background: rgba(0, 0, 0, 0.25);
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--border-radius-xl);
  margin-top: var(--spacing-xs);
  transition: background 0.3s ease;
}

.footer-link img {
  width: var(--size-md);
  height: var(--size-md);
  margin-right: var(--spacing-xs);
}

.footer-link:hover {
  background: var(--color-background-dark);
}

.footer-right {
  padding: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  overflow: visible;
  height: 100%;
  padding-right: var(--spacing-xl);
  padding-left: var(--spacing-xl);
  padding-top: 0;
  max-width: var(--size-8xl);
}

.footer-right-background {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: var(--z-base);
  width: 100%;
}

.footer-right h6,
.footer-right a {
  position: relative;
  z-index: var(--z-modal);
  color: var(--color-secondary);
}

@media screen and (max-width: 1024px) {
  .footer-content {
    padding: 0 var(--spacing-2xl);
  }
  .footer-button {
    font-size: var(--font-size-sm);
  }
  .footer-right-background {
    height: var(--size-14xl);
  }
}

@media screen and (max-width: 766px) {
  .footer-content {
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-xl) 0;
  }
  .footer-right-background {
    display: none;
  }
  .footer-right a, .footer-right h6 {
    color: var(--color-text);
  }
  .footer-right {
    padding: 0;
  }
}

/* ==================== */  
/* LANGUAGE SWITCHER */ 
/* ==================== */

.language-switcher {
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
  z-index: 1000;
}

.language-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.language-button:hover {
  opacity: 1 !important;
}

.language-button img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.floating-projects-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background: rgba(150, 85, 166, 0.9);
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  z-index: 1000;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.3s ease;
}

.floating-projects-button:hover {
  transform: translateY(-2px);
  background: rgba(150, 85, 166, 1);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .floating-projects-button {
    bottom: 1rem;
    right: 1rem;
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

.rotated {
  transform: rotate(180deg);
}

.floating-projects-button:hover .rotated {
  transform: rotate(180deg) translateY(2px);
}

.button-icon {
  width: var(--size-sm);
  height: var(--size-sm);
  margin-left: var(--spacing-xs);
  margin-bottom: calc(var(--spacing-3xs) * -1);
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
}

.close-button:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: scale(1.1);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  width: 90%;
  height: 90%;
  border-radius: 8px;
  overflow: hidden;
}





